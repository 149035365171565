<app-loading-spinner *ngIf="isLoading()"></app-loading-spinner>

<div class="row m-t-5" *ngIf="showNoLongerAccessTicketError">
  <div class="sectionBox">
    <h2>You no longer have access to this ticket.</h2>
    <h3>Did you recently change the group of this ticket to a group you are not a member of?</h3>
  </div>
</div>

<div *ngIf="!isLoading() && !showNoLongerAccessTicketError" class="row m-t-5">
  <div [ngClass]="compactView ? 'col-lg-7' : 'col-lg-8'" class="p-l-5">
    <div *ngIf="!requestDetails.ChatConversationId" id="topTabs">
      <div class="row">
        <div class="col col-xl-2">
          <div [class.active]="selectedTab == 'details'" (click)="showAddtask()"
            class="dashboardTile small divLink text-center" tabindex="0" ng-reflect-router-link="/agent/dashboard/stats"
            ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]">
            <p style="font-size: 13px;">{{ isCmdbEnabled() ? 'Asset Details' : 'Ticket Details' }}</p>
          </div>
        </div>

        <div class="col col-xl-2" *ngIf="!isCmdbEnabled()">
          <div [class.active]="selectedTab == 'tasks'" (click)="selectedTab = 'tasks'"
            class="dashboardTile small divLink text-center" tabindex="0" ng-reflect-router-link="/agent/dashboard/stats"
            ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]">
            <p style="font-size: 13px;">Tasks <span *ngIf="requestDetails.TaskListId"
                style="background-color: indianred;color: white;border-radius: 10px;padding: 5px;margin-left: 3px;padding-left: 9px;padding-right: 9px;">
                {{this.incompleteTaskCount()}}
              </span>
            </p>

          </div>
        </div>

        <div class="col col-xl-2" *ngIf="!isCmdbEnabled() && requestDetails.EligibleForSurvey && ViewSurvey && requestDetails.AgentAdminEligible">
          <div [class.active]="selectedTab == 'survey'" (click)="selectedTab = 'survey'; getSurveyDetails()"
            class="dashboardTile small divLink text-center" tabindex="0" ng-reflect-router-link="/agent/dashboard/stats"
            ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]">
            <p style="font-size: 13px;">Survey Result
            </p>
          </div>
        </div>

      </div>

      <div *ngIf="selectedTab == 'details'" style="border-top-left-radius: 0px" class="sectionBox">
        <ng-container *ngIf="!isCmdbEnabled()">
          <i (click)="showEditSubjectBody = !showEditSubjectBody" *ngIf="!requestDetails.Closed"
            class="fas fa-pen float-end divLink fadeIcon"></i>
        </ng-container>
        <ng-container *ngIf="isCmdbEnabled()">
          <i (click)="showEditSubjectBody = !showEditSubjectBody" *ngIf="accessPermissions?.AssetManagementAdmin !== 0"
            class="fas fa-pen float-end divLink fadeIcon"></i>
          <i *ngIf="accessPermissions?.AssetManagementAdmin == 0" class="fas fa-pen float-end divLink fadeIcon"></i>
        </ng-container>
        <div *ngIf="!showEditSubjectBody">
          <h4><strong >{{requestDetails?.RequestSubject}}</strong></h4>
          <div style="max-height: 550px; overflow-y: auto;" class="maxImg" [innerHTML]="requestDetails?.RequestBody">
          </div>

          <!-- ImportedDataFields-->

          <table *ngIf="requestDetails?.ImportedDataFields?.length"
            style="padding: 10px; background-color: #f1f1f1; margin-top: 10px; max-width: 500px;"
            class="table tableStyle">
            <tbody>
              <tr *ngFor="let field of requestDetails.ImportedDataFields">
                <th>{{field.PropertyName}}</th>
                <td>{{field.PropertyValue}}</td>
              </tr>
            </tbody>
          </table>


        </div>

        <div *ngIf="showEditSubjectBody">
          <div class="row">
            <div class="col-sm-2 formHeader alignMiddle p-10">
              Subject
            </div>
            <div class="col-sm p-10">
              <input [(ngModel)]="requestDetails.RequestSubject" type="text" class="form-control" id="Subject"
                placeholder="Enter Subject" autocomplete="off">

            </div>
          </div>

          <div class="row">
            <div class="col-sm-2 formHeader p-10">
              Description
            </div>
            <div class="col-sm p-10">
              <quill-editor [(ngModel)]="requestDetails.RequestBody" [styles]="{height: '150px'}"
                placeholder=""></quill-editor>
            </div>
          </div>

          <div class="m-t-20">
            <div (click)="updateSubjectBody()" class="btn btn-primary">Save</div>
          </div>

        </div>

        <div class="row">
          <div class="col">
            <div *ngFor="let attachment of requestDetails.Attachments"
              (click)="downloadAttachment(attachment.AttachmentId)"
              class="btn btn-outline-secondary btn-sm attachmentButton m-r-5 m-t-10">
              <i class="fas fa-file m-r-5"></i>
              <span>{{attachment.AttachmentName}}</span>
            </div>
          </div>
        </div>

        <div class="row m-t-15">
          <div class="col">
            <span *ngIf="requestDetails.Followers.length > 0" (click)="openFollowersModal()"
              class="p-10 requestBodyButton divLink"> <i
                class="fas fa-eye m-r-5"></i>{{requestDetails.Followers.length}} {{requestDetails.Followers.length > 1 ?
              'Followers' : 'Follower'}}</span>
            <span *ngIf="requestDetails.Links.length > 0" (click)="openShowLinksModal()"
              class="p-10 requestBodyButton divLink"> <i
                class="fas fa-paperclip m-r-5"></i>{{requestDetails.Links.length}} {{requestDetails.Links.length > 1 ?
              'Links' : 'Link'}}</span>
          </div>
        </div>

      </div>

      <div *ngIf="selectedTab == 'tasks'" style="border-top-left-radius: 0px; min-height: 75px" class="sectionBox">


        <div *ngIf="!requestDetails.TaskListIdNew">

          <div (click)="findTaskListModal()" class="btn btn-primary btn-sm float-end">
            <i class="fas fa-search" aria-hidden="true"></i> Add Task List
          </div>

          <button (click)="create()" [disabled]="!isaddTask" class="btn btn-primary btn-sm float-end me-2">
            <i class="fas fa-plus"></i> Tasks
          </button>

          <p style="font-size: 16px; padding-top: 10px; padding-left: 10px; ">There is no task list associated with this
            ticket.</p>

          <app-tasks [requestId]="requestId" (refreshParentComponent)="refreshData()" [requestComponent]="this"></app-tasks>

        </div>

        <div *ngIf="requestDetails.TaskListId" class="row">
          <div class="col-12">

            <div class="row m-b-5">
              <div class="col-4" style="padding-left: 10px; font-size: 16px">Task</div>
              <div class="col" style="padding-left: 10px; font-size: 16px">Instructions


              </div>
            </div>

            <div *ngFor="let task of decodedTaskList" class="row taskList">
              <div class="col-4 title">{{task.TaskName}}</div>
              <div class="col description" [innerHTML]="task.Instructions"></div>
              <i *ngIf="!task.Completed" (click)="task.Completed = true; this.saveTaskList()"
                class="fas fa-times sdesk-check divLink"></i>
              <i *ngIf="task.Completed" (click)="task.Completed = false; this.saveTaskList()"
                class="fas fa-check sdesk-check completed divLink"></i>
            </div>

          </div>
        </div>
        <div *ngIf="requestDetails.TaskListIdNew">

          <div (click)="findTaskListModal()" class="btn btn-primary btn-sm float-end">
            <i class="fas fa-search" aria-hidden="true"></i> Add Task List
          </div>

          <button (click)="create()" [disabled]="!isaddTask" class="btn btn-primary btn-sm float-end me-2">
            <i class="fas fa-plus"></i> Tasks
          </button>

          <app-tasks [requestId]="requestDetails.Id" (refreshParentComponent)="refreshData()"></app-tasks>

        </div>

      </div>
      <div *ngIf="selectedTab == 'survey' && ViewSurvey && requestDetails.AgentAdminEligible && surveyDetails.SurveyResult" style="border-top-left-radius: 0px; min-height: 75px" class="sectionBox">
          <h2>Survey Details</h2>

          <div class="survey-summary">
            <p><strong>Survey Result:  </strong> <span class="rating-box" [ngClass]="getSurveyBackgroundClass(surveyDetails?.SurveyResult?.Rating)">{{ surveyDetails?.SurveyResult?.SurveyResult }}</span></p>
            <p><strong>Comment:</strong> <span class="comment-box">{{ surveyDetails?.SurveyResult?.Comment }}</span></p>
          </div>

  <div class="survey-answers">
    <h3>Survey Answers</h3>
        <div *ngFor="let answer of surveyDetails?.SurveyAnswer" class="answer-box">
          <p><strong>Question:</strong> {{ answer?.Question }} <span class="text-danger" *ngIf="answer?.IsRequired">*</span></p>
          <p><strong>Answer:</strong> {{ answer?.Answer }}</p>
        </div>
      </div>
      </div>
    </div>


    <div *ngIf="!requestDetails.ChatConversationId">
      <app-request-major-incident-management *ngIf="requestDetails.MIModuleEnabled" [requestDetails]="requestDetails"
        (refreshData)="refreshData()"></app-request-major-incident-management>

      <app-request-change-management *ngIf="requestDetails.ChangeManagementEnabled"
        [requestDetails]="requestDetails"
        (refreshData)="refreshData()">
      </app-request-change-management>

      <app-request-thread-actions *ngIf="!requestDetails.Closed" [selectedThreadId]="selectedThreadId"
        [requestDetails]="requestDetails" [requestOptions]="requestOptions" [requestThread]="requestThread" [userpermissions]="accessPermissions"
        (refreshData)="refreshData()" (threadIdChanged)="selectedThreadId = $event"></app-request-thread-actions>

      <app-request-thread [requestThread]="requestThread" [selectedThreadId]="selectedThreadId"
        (threadIdChanged)="selectedThreadId = $event" [requestDetailsClosed]="requestDetails.Closed" (refreshData)="refreshData()"></app-request-thread>

      <app-request-major-incident-thread [requestDetails]="requestDetails"></app-request-major-incident-thread>
    </div>
    <div *ngIf="requestDetails.ChatConversationId">

      <app-chat-window [conversationId]="requestDetails.ChatConversationId"
        (refresh)="refreshRequestDetails()"></app-chat-window>
    </div>

  </div>
  <div [ngClass]="compactView ? 'col-lg-5' : 'col-lg-4'" class="p-l-5">

    <div *ngIf="requestDetails.ChangeManagementAwaitingApproval" class="alert alert-grey m-b-5">
      <p><strong>
          {{requestDetails.Type}} is awaiting your approval
        </strong></p>

      <div (click)="approveChange()" class="btn btn-green" data-bs-dismiss="modal">Approve</div>
      <div (click)="rejectChange()" class="btn btn-cancel" data-bs-dismiss="modal">Reject</div>

    </div>

    <div *ngIf="realTimeInitiated && realTimeOnlineUsers.length" class="alert alert-success m-b-5">
      <i class="fas fa-eye m-r-5"></i>
      <strong>
        <span *ngFor="let user of realTimeOnlineUsers; first as isFirst; last as isLast">{{isFirst ? '' : isLast ? ' and
          ' : ', '}}{{user.Name}}</span>
      </strong> {{((realTimeOnlineUsers).length > 1) ? 'are' : 'is'}} also viewing
    </div>

    <div *ngIf="realTimeInitiated && hasRequestBeenUpdated()" (click)="refreshData();removeToEmailFromCC();"
      class="alert alert-success m-b-5 divLink">
      <i class="fas fa-sync m-r-5"></i>
      This {{requestDetails.Type}} has been updated. <strong>Click here to refresh</strong>
    </div>

    <a *ngIf="requestDetails.HeadsUp_ChangeRequest && requestId != requestDetails.HeadsUp_ChangeRequest"
      [routerLink]="['/agent/request/',requestDetails.HeadsUp_ChangeRequest]">
      <div class="alert alert-warning m-b-5 divLink">
        <i class="fas fa-exclamation-triangle m-r-5"></i>
        There is an active <strong>Change Request</strong> for this service
      </div>
    </a>

    <a *ngIf="requestDetails.HeadsUp_MajorIncident && requestId != requestDetails.HeadsUp_MajorIncident"
      [routerLink]="['/agent/request/',requestDetails.HeadsUp_MajorIncident]">
      <div class="alert alert-warning m-b-5 divLink">
        <i class="fas fa-exclamation-triangle m-r-5"></i>
        There is a <strong>Major Incident</strong> affecting this service
      </div>
    </a>

    <div class="group-sticky">
    <div class="btn-group sdesk-button-group-100 ">
      <a *ngIf="!requestDetails.Closed && !requestDetails.MIModuleEnabled && !isCmdbEnabled()" (click)="convert()"
        class="btn btn-lightblue-block" aria-current="page">Convert</a>
      <a *ngIf="!requestDetails.Closed && (requestDetails.AvailableLinks).length > 0" (click)="link()"
        class="btn btn-lightblue-block" aria-current="page">Link</a>
      <a (click)="auditLog()" class="btn btn-lightblue-block" aria-current="page">Audit Log</a>
      <span *ngIf="processingFollow" class="btn btn-lightblue-block">
        <i class="fas fa-spinner fa-spin"></i>
      </span>
      <a *ngIf="!processingFollow && !requestDetails.Closed && !requestDetails.ChatConversationId" (click)="toggleFollow()"
        class="btn btn-lightblue-block" aria-current="page">{{requestDetails.AmIFollowing ? 'Unfollow' : 'Follow'}}</a>
    </div>

      <div *ngIf="modelValueChanged">
        <a class="btn btn-save" aria-current="page" (click)="saveAllCustomFields(requestDetails)">Save</a>
      </div>
    </div>
    <app-loading-spinner *ngIf="isLoadingRight"></app-loading-spinner>
    <div style="padding-left: 6px;">
    <div class="sectionBox p-0" *ngIf="!isLoadingRight">
      <div class="table-responsive" style="overflow-x: visible;">
        <table class="table tableStyle m-0">
          <tbody>
            <tr>
              <th scope="row">{{ isCmdbEnabled() ? 'Asset No.' : (requestDetails?.Type + ' Id') }}</th>
              <td>
                {{isCmdbEnabled() ? requestDetails?.Asset?.AssetNo : requestDetails?.LocalId}}
              </td>

          </tr>
          <tr *ngIf="!isCmdbEnabled()">
            <th scope="row">Request Type</th>
            <td>{{requestDetails?.Type}}</td>
          </tr>
          <tr>
            <th scope="row">Opened</th>
            <td>
              <app-friendly-time [utcTimeData]="requestDetails?.date_browser" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ isCmdbEnabled() ? 'Owner' : 'Requester' }}</th>
            <td>
              <ng-container *ngIf="!isCmdbEnabled()">
              <i *ngIf="!requestDetails.Closed" (click)="openUserSearchModal()"
                class="fas fa-user-edit float-end p-5 divLink fadeIcon"></i>
              </ng-container>
              <ng-container *ngIf="isCmdbEnabled() && accessPermissions">
                <i *ngIf="!requestDetails.Closed && accessPermissions?.AssetManagementAdmin !== 0" (click)="openUserSearchModal()"
                class="fas fa-user-edit float-end p-5 divLink fadeIcon"></i>
                <i *ngIf="accessPermissions?.AssetManagementAdmin == 0"
                class="fas fa-user-edit float-end p-5 divLink fadeIcon"></i>
              </ng-container>


              {{requestDetails?.Requester}}
              <br>
              {{requestDetails?.RequesterEmailAddress}} <strong>{{ (requestDetails?.RequesterPreferredContactMethod ==
                1)
                ? '(Preferred)' : ''}}</strong><br>
              {{requestDetails?.RequesterPhoneNumber}} <strong>{{ (requestDetails?.RequesterPreferredContactMethod == 2)
                ?
                '(Preferred)' : ''}}</strong>
            </td>
          </tr>
          <tr *ngIf="this.environmentParams?.ShowExtendedUserParameters && !isCmdbEnabled()">
            <td style="padding: 5px 5px 5px 15px; border-left: solid">Job Title</td>
            <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.RequesterJobTitle}}</td>
          </tr>

          <tr *ngIf="this.environmentParams?.ShowExtendedUserParameters && !isCmdbEnabled()">
            <td style="padding: 5px 5px 5px 15px; border-left: solid">Department</td>
            <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.RequesterDepartment}}</td>
          </tr>

          <tr *ngIf="this.environmentParams?.ShowExtendedUserParameters && !isCmdbEnabled()">
            <td style="padding: 5px 5px 5px 15px; border-left: solid">Line Manager</td>
            <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.RequesterLineManager}}</td>
          </tr>

            <tr *ngIf="isCmdbEnabled()">
              <td style="padding: 5px 5px 5px 15px; border-left: solid">Asset Type</td>
              <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.Asset?.AssetType}}</td>
            </tr>

            <tr *ngIf="isCmdbEnabled()">
              <td style="padding: 5px 5px 5px 15px; border-left: solid">Serial Number</td>
              <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.Asset?.SerialNumber}}</td>
            </tr>

            <tr *ngIf="isCmdbEnabled()">
              <td style="padding: 5px 5px 5px 15px; border-left: solid">Imei</td>
              <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.Asset?.Imei}}</td>
            </tr>

            <tr *ngIf="isCmdbEnabled()">
              <td style="padding: 5px 5px 5px 15px; border-left: solid">Manufacturer</td>
              <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.Asset?.Manufacturer}}</td>
            </tr>

            <tr *ngIf="isCmdbEnabled()">
              <td style="padding: 5px 5px 5px 15px; border-left: solid">Model</td>
              <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.Asset?.Model}}</td>
            </tr>

            <tr *ngIf="isCmdbEnabled()">
              <td style="padding: 5px 5px 5px 15px; border-left: solid">Cost</td>
              <td style="padding: 5px 5px 5px 8px" class="ng-binding">{{requestDetails?.Asset?.Cost}}</td>
            </tr>

            <tr *ngIf="requestOptions.customer">
              <th>Customer</th>
              <td>
                {{requestDetails?.CurrentCustomer}}
                <i (click)="openCustomerServiceSearchModal()" *ngIf="!requestDetails.Closed"
                  class="fas fa-search float-end p-5 divLink fadeIcon"></i>
              </td>
            </tr>

            <tr *ngIf="requestOptions.customer">
              <th>Location </th>
              <td>{{requestDetails?.CurrentCustomerLocation}}
                <i (click)="openCustomerServiceSearchModal()" *ngIf="!requestDetails.Closed"
                  class="fas fa-search float-end p-5 divLink fadeIcon"></i>
              </td>
            </tr>

            <tr *ngIf="requestOptions.service">
              <th>Service</th>
              <td class="requestPropertiesLink"><span (click)="openServiceModal()"
                  class="divLink">{{requestDetails?.CurrentService}}</span>
                <i (click)="openCustomerServiceSearchModal()" *ngIf="!requestDetails.Closed"
                  class="fas fa-search float-end p-5 divLink fadeIcon"></i>
              </td>
            </tr>
            <tr class="text-break" *ngIf="requestDetails?.Type === 'Change Request'">
              <th scope="row">Change State</th>
              <td>
                <div class="input-group w-100">
                  <input type="text" class="form-control input-sm select2" [(ngModel)]="changeState" [clearable]="false"
                    readonly>
                </div>
              </td>
            </tr>

            <tr *ngIf="requestOptions.impact">
              <th scope="row">Impact
                <span *ngIf="requestDetails.Required.ImpactRequired === 1" style="color:#d67b7b;">*</span>
              </th>
              <td>

                <ng-select [items]="requestOptions.impact" bindLabel="name" bindValue="id"
                  [(ngModel)]="requestDetails.ImpactId" [clearable]="false"
                  [disabled]="requestDetails.Closed" (ngModelChange)="onValueChanged('impact',requestDetails)">
                </ng-select>
              </td>
            </tr>

            <tr *ngIf="requestOptions.urgency">
              <th scope="row">Urgency
                <span *ngIf="requestDetails.Required.UrgencyRequired === 1" style="color:#d67b7b;">*</span>
              </th>
              <td>
                <ng-select [items]="requestOptions.urgency" bindLabel="name" bindValue="id"
                  [(ngModel)]="requestDetails.UrgencyId" [clearable]="false"
                  [disabled]="requestDetails.Closed" (ngModelChange)="onValueChanged('urgency',requestDetails)">
                </ng-select>
              </td>
            </tr>

            <tr *ngIf="requestOptions.priority">
              <th scope="row">Priority
                <span *ngIf="requestDetails.Required.PriorityRequired === 1" style="color:#d67b7b;">*</span>
              </th>
              <td>


                <ng-select *ngIf="!requestDetails.AutoPriority || requestDetails.PriorityOverride"
                  [items]="requestOptions.priority" bindLabel="name" bindValue="id"
                  [(ngModel)]="requestDetails.PriorityId" [clearable]="false" (ngModelChange)="onValueChanged('priority',requestDetails)"
                  [disabled]="(requestDetails.AutoPriority && !requestDetails.PriorityOverride) || requestDetails.Closed">
                </ng-select>

                <button
                  *ngIf="!requestDetails.PriorityOverride && !requestDetails.Closed && requestDetails.AutoPriority"
                  (click)="overRidePriorityDialog()" class="btn btn-primary btn-sm float-end" type="button">Override</button>

                <p *ngIf="!requestDetails.PriorityOverride && requestDetails.AutoPriority" class="m-t-5">
                  {{requestDetails.CurrentPriority}}</p>

              </td>
            </tr>

            <tr *ngIf="!isCmdbEnabled()">
              <th scope="row">Group
                <span style="color:#d67b7b;">*</span>
              </th>
              <td>
                <ng-select [items]="requestOptions.group" bindLabel="name" bindValue="id"
                  [(ngModel)]="requestDetails.GroupId" [clearable]="false" (ngModelChange)="onValueChanged('group',requestDetails);filteragents(requestDetails)"
                  [disabled]="requestDetails.Closed">
                </ng-select>

              </td>
            </tr>

            <tr *ngIf="!isCmdbEnabled()">
              <th scope="row">
                {{requestDetails.MIModuleEnabled ? 'MI Manager' : requestDetails.ChangeManagementEnabled ? 'Change Owner' : 'Agent'}}
                <span style="color:#d67b7b;">*</span>
              </th>
              <td>
                <ng-select
                  [items]="requestDetails.ResolvedAgent && requestDetails.ResolvedAgent.length > 0 ? requestDetails.ResolvedAgent : requestOptions.agent"
                  bindLabel="name"
                  bindValue="id"
                  (focus)="onFocus(requestDetails)"
                  [(ngModel)]="requestDetails.AgentId"
                  [clearable]="false"
                  (ngModelChange)="onValueChanged('agent', requestDetails)"
                  [disabled]="!requestDetails.GroupId || requestDetails.Closed">
                </ng-select>
              </td>
            </tr>

            <tr *ngIf="requestDetails.ChangeManagementEnabled">
              <th scope="row"> Change Manager</th>
              <td>

                <!--                       ToDo: Sort out Change Manager onChange-->
                <ng-select [items]="requestOptions.changeManagers" bindLabel="name" bindValue="id"
                  [(ngModel)]="requestDetails.ChangeManagement.ChangeManagerUserId" (ngModelChange)="onValueChanged('manager',requestDetails)"
                  [clearable]="false" [disabled]="requestDetails.Closed">
                </ng-select>
              </td>
            </tr>

            <tr>
              <th scope="row">Status </th>
              <td>
                <span *ngIf="requestDetails.Closed">{{requestDetails.CurrentStatus}}</span>

              <ng-select *ngIf="!requestDetails.Closed" [items]="requestOptions.status" bindLabel="name" bindValue="id"
                [(ngModel)]="requestDetails.StatusId" [clearable]="false" (ngModelChange)="onValueChanged('status',requestDetails)"
                [disabled]="(requestDetails.Closed) || (isCmdbEnabled() && accessPermissions?.AssetManagementAdmin == 0)">
              </ng-select>
            </td>
          </tr>

          <tr *ngFor="let customField of requestDetails.CustomFields" class="text-break">
            <th scope="row">{{customField.Name}}
              <span *ngIf="customField.Required == 1" style="color:#d67b7b;">*</span>
            </th>
            <td>
              <div *ngIf="customField.FieldType == 1" class="input-group w-100">
                <input [(ngModel)]="customField.tempValue" (ngModelChange)="customField.valueChanged = true;onValueChanged('field1',requestDetails)"
                  [disabled]="(requestDetails.Closed) || (isCmdbEnabled() && accessPermissions?.AssetManagementAdmin == 0)" type="text" class="form-control input-sm select2">
                <!-- <button *ngIf="customField.valueChanged != undefined"
                  (click)="saveCustomFieldTextValue(customField)"
                  class="btn btn-primary btn-sm float-end" type="button">Save</button> -->


                <!-- ToDo: Update this so we dont need to click save.. maybe an auto save after x number of seconds? -->
              </div>
              <div *ngIf="customField.FieldType == 2">
                <app-custom-field-dropdown (optionOutput)="onValueChanged('field2',requestDetails);getNewValue($event)"
                  [customFieldId]="customField.Id"
                  [customFieldOptions]="customField.Options"
                  [selectedLevelValues]="customField.LevelValues"
                  [isDisabled]="(requestDetails.Closed) || (isCmdbEnabled() && accessPermissions?.AssetManagementAdmin == 0)">
                </app-custom-field-dropdown>
              </div>

              <div *ngIf="customField.FieldType == 3">
              <p-calendar
              [(ngModel)]="customField.tempValue"
              [showIcon]="true"
              ariaLabel="Date"
              [iconDisplay]="'input'"
              inputId="Planning_ScheduledStart"
              [readonlyInput]="true"
              [showButtonBar]="true"
              [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
              (ngModelChange)="customField.valueChanged = true;onValueChanged('field1',requestDetails)"
              [disabled]="(requestDetails.Closed) || (isCmdbEnabled() && accessPermissions?.AssetManagementAdmin == 0)"
            ></p-calendar>

                <!-- <input type="date" [(ngModel)]="customField.tempValue" (ngModelChange)="customField.valueChanged = true;onValueChanged('field1',requestDetails)"
                            class="form-control input-sm select2"  autocomplete="off" autocomplete="off" [disabled]="(requestDetails.Closed) || (isCmdbEnabled() && accessPermissions?.AssetManagementAdmin == 0)"> -->
                <!-- <button *ngIf="customField.valueChanged != undefined"
                  (click)="saveCustomFieldTextValue(customField)"
                  class="btn btn-primary btn-sm float-end" type="button">Save</button> -->


                <!-- ToDo: Update this so we dont need to click save.. maybe an auto save after x number of seconds? -->
              </div>
            </td>
          </tr>


          </tbody>
        </table>
      </div>
      <div class="btn-group sdesk-button-group-100">
        <span *ngIf="processingReopen" class="btn btn-lightblue-block" style="cursor: default; pointer-events: none;">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        <a *ngIf="!processingReopen && requestDetails.Closed && !requestDetails.ChatConversationId" (click)="reOpenTicket()"
          class="btn btn-lightblue-block" aria-current="page">Reopen</a>
      </div>
    </div>
  </div>
    <app-request-sla *ngIf="requestDetails.Sla" [requestDetails]="requestDetails"></app-request-sla>

  </div>
</div>
